@import 'react-multi-carousel/lib/styles.css';
@import 'theme';

* {
  font-family: "Outfit", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-optical-sizing: auto;
}



#main-navbar {

  .logo {
    max-height: 3.5rem;
    @include media-breakpoint-up(lg) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  .nav-link {
    padding-top: 0.1rem;
    padding-bottom: 0.2rem;
    padding-right: 1rem;
    padding-left: 1rem;
    font-size: 1.2rem;
  }
}

#carousel-inicio {
  .carousel-inner {
    overflow: initial;
    width: 102%;
    @include media-breakpoint-up(md) {
      width: 103.9%;
    }
  }

  .carousel-indicators {
    bottom: 1rem;
    @include media-breakpoint-up(md) {
      bottom: 3rem;
    }
  }
}

.ambiente-container {
  .ambiente-skeleton {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    background-color: #f5f5f5;
    -webkit-filter: blur(.5rem);
    -moz-filter: blur(.5rem);
    -o-filter: blur(.5rem);
    -ms-filter: blur(.5rem);
    filter: blur(.5rem);
    opacity: .5;
  
    @include media-breakpoint-up(md) {
      background-color: transparent;
      background: none !important;
      opacity: 1;
    }
  }
  
  .ambiente-img {
    object-fit: contain;
    height: 20rem;
    @include media-breakpoint-up(md) {
      height: 100%;
    }
  }

  .ambiente-description {
    position: absolute;
    top: 12px;
    bottom: 12px;
    left: 12px;
    right: 12px;
    background-color: rgba(0,0,0,.45);
    z-index: 3;
    opacity: 0;
    transition: opacity 100ms ease-in;

    &:hover {
      opacity: 1;

      & + .ambiente-img {
        -webkit-filter: blur(.5rem);
        -moz-filter: blur(.5rem);
        -o-filter: blur(.5rem);
        -ms-filter: blur(.5rem);
        filter: blur(.5rem);
      }
    }

    @include media-breakpoint-up(md) {
      top: 0;
      bottom: 0;
    }

    p, h4 {
      text-shadow: 1px 1px 3px rgba(0,0,0, .5);
      max-width: 20rem;
    }
  }
}

.ambientes-dots {
  bottom: 1rem;
}

.comprar-ahora {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  h2 {
    text-shadow: 2px 2px 5px rgba(0,0,0, .5);
  }
}

.logo-footer {
  max-height: 4.5rem;
}

.whatsapp-float {
  z-index: 1000;
  right: 2rem;
  bottom: 2rem;
  width: 4.6rem;
  height: 4.6rem;
  opacity: .85;
  transition: opacity 100ms ease-in;

  &:hover {
    opacity: 1;
  }

  @include media-breakpoint-up(md) {
    right: 3rem;
    bottom: 3rem;
  }

  @include media-breakpoint-up(lg) {
    right: 5rem;
    bottom: 5rem;
  }
}

.product-description {
  position: absolute;
  top: 12px;
  bottom: 12px;
  left: 7px;
  right: 7px;
  background-color: rgba(0,0,0,.45);
  z-index: 3;
  opacity: 0;
  transition: opacity 100ms ease-in;

  &:hover {
    opacity: 1;

    & + .product-img {
      -webkit-filter: blur(.5rem);
      -moz-filter: blur(.5rem);
      -o-filter: blur(.5rem);
      -ms-filter: blur(.5rem);
      filter: blur(.5rem);
    }
  }

  @include media-breakpoint-up(md) {
    top: 0;
    bottom: 0;
  }

  p, h4 {
    text-shadow: 1px 1px 3px rgba(0,0,0, .5);
    max-width: 20rem;
  }
}